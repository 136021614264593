import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Post from './DiscussionPostComponent';
import DiscussionSkeleton from "../../Skeletons/DiscussionSkeleton";

const DiscussionPostList = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    

    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `https://www.cricdiction.com/wp-json/wp/v2/posts?categories=476&per_page=3`
                );
                setPosts(prevPosts => [...prevPosts, ...response.data]);
            } catch (error) {
                console.error('Error fetching posts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    return (
        <>
            <div className="grid gap-8 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
                {posts.map(post => (
                    <Post key={post.id} post={post} />
                ))}
            </div>
            {loading && <div className="discussion-skeleton grid gap-8 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1"><DiscussionSkeleton items={3} /></div>}
        </>
    );
};

export default DiscussionPostList;