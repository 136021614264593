import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Post = ({ post }) => (
  	<div className="discussion-grid-wrap">
		<Link className="discussion-link" to={'/'+post.id+'/'+post.slug}>
			<div className="discussion-item">
                <div className="dateTime">
                    <p className="date">{ moment(post.date).format("D MMM YYYY") }</p>
                </div>
                <h2 className="title" dangerouslySetInnerHTML={{ __html:post.title.rendered }}></h2>
	            <div className="thum">
                    <img src={post.yoast_head_json.og_image[0].url ?? '..//..//assets/media/550.png'} alt="" />
                </div>
            </div>
		</Link>
  	</div>
);

export default Post;