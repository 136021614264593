import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import axios from "axios";
import FixtureCard from "../../Cards/FixtureCard";
import moment from 'moment';


const PredictionList = () => {
    // State to keep track of the active tab index
    
    const [todayLoading, setTodayLoading] = useState(false);
    const [todayFixtures, setTodayFixtures] = useState([]);

    const [upcomingLoading, setUpcomingLoading] = useState(false);
    const [upcomingFixtures, setUpcomingFixtures] = useState([]);

    const [resultsLoading, setResultsLoading] = useState(false);
    const [resultFixtures, setResultFixtures] = useState([]);


    const todayDate = moment().format('DD MMM YYYY');


    const fetchResultFixtures = async () => {
        setResultsLoading(true);
        try {
            const response = await axios.get(
                `https://cricdiction.expert/wp-json/cricdiction/v1/latest-events-details?view=previous`
            );
            const previous_result = Object.keys(response.data).map((key) => response.data[key]);

            console.log("response_previous", previous_result);
            setResultFixtures(previous_result);
            setResultsLoading(false);
        } catch (error) {
            console.error('Error fetching fixtures:', error);
        } finally {
            setResultsLoading(false);
        }
    };

    const fetchTodayFixtures = async () => {
        setTodayLoading(true);
        try {
            const response = await axios.get(
                `https://cricdiction.expert/wp-json/cricdiction/v1/latest-events-details`
            );

            const result = Object.keys(response.data).map((key) => response.data[key]);

            console.log("response", result);
            setTodayFixtures(result);
            setTodayLoading(false);
        } catch (error) {
            console.error('Error fetching fixtures:', error);
        } finally {
            setTodayLoading(false);
        }
    };

    const fetchUpcomingFixtures = async () => {
        setUpcomingLoading(true);
        try {
            const response = await axios.get(
                `https://cricdiction.expert/wp-json/cricdiction/v1/latest-events-details?view=upcoming`
            );
            const upcoming_result = Object.keys(response.data).map((key) => response.data[key]);

            console.log("response_upcoming", upcoming_result);
            setUpcomingFixtures(upcoming_result);
            setUpcomingLoading(false);
        } catch (error) {
            console.error('Error fetching fixtures:', error);
        } finally {
            setUpcomingLoading(false);
        }
    };

    useEffect(() => {
        fetchTodayFixtures();
        fetchResultFixtures();
        fetchUpcomingFixtures();
     
    }, []);


    console.log("todayFixtures", todayFixtures);

    return(
        <>
            <Helmet>
                <title>Criclysis Cricket Predictions - Match Predictions and Analysis</title>
                <meta name="keywords" content="cricket predictions, match predictions, cricket analysis, cricket experts, cricket matches, cricket teams, cricket players" />
                <meta name="description" content="Explore Criclysis Cricket Predictions for insightful match predictions and in-depth analysis. Get expert opinions on cricket matches, teams, and players." />
            </Helmet>


            <div className="main-wrapper">
				<div className="main-content">
                    <div className="site-container">
                        <Tabs className="calendar-tabs">
                            <TabList className="tab-list">
                                <Tab className="tab-item today">Today<span>( { todayDate } )</span></Tab>
                                <Tab className="tab-item upcoming">Upcoming<span>( +7 days )</span></Tab>
                                <Tab className="tab-item result">Results<span>( -7 days )</span></Tab>
                            </TabList>
                            <TabPanel>
                                <div className="tab-content">
                                    {
                                        todayLoading ? 
                                        <h1>Loading...</h1>
                                        : 
                                        todayFixtures.length ? (
                                            todayFixtures.map((category, index) => {return (
                                                <FixtureCard key={index} category={category} />
                                            )})
                                        ) :'No Matches Found!'
                                    }
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="tab-content">
                                    {
                                        upcomingLoading ? 
                                        <h1>Loading...</h1>
                                        : 
                                        upcomingFixtures.length ? (
                                            upcomingFixtures.map((category, index) => {return (
                                                <FixtureCard key={index} category={category} />
                                            )})
                                        ) :'No Matches Found!'
                                    }
                                </div>
                            </TabPanel>
                            <TabPanel>
                            <div className="tab-content">
                                    {
                                        resultsLoading ? 
                                        <h1>Loading...</h1>
                                        : 
                                        resultFixtures.length ? (
                                            resultFixtures.map((category, index) => {return (
                                                <FixtureCard key={index} category={category} />
                                            )})
                                        ) :'No Matches Found!'
                                    }
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
				</div>
			</div>

        </>
    )
}
export default PredictionList;