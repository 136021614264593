import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import "swiper/css";
import DiscussionSkeleton from "../../Skeletons/DiscussionSkeleton";

const Discussion = () => {

    const [discussions, setDiscussions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getDiscussionPost();
    }, [])

    var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');

    const getDiscussionPost = async () => {
        fetch('https://www.cricdiction.com/wp-json/wp/v2/posts?categories=476&per_page=6', { cache: 'no-store' })
        .then(response => response.json())
        .then(discussions => {
            setDiscussions(discussions);
            setIsLoading(false);
        })
    }
  
    return (
        <div className="discussion-post-slider">
            <div className="site-container">
                <div className="section-title">
                    <h1 className="title">Live Cricket Prediction, Tips, In-play Session</h1>
                </div>
                {isLoading && <div className="discussion-skeleton grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-6"><DiscussionSkeleton items={3} /></div>}
                <Swiper
                    spaceBetween={18}
                    slidesPerView={3}
                    breakpoints={{
                        280: { slidesPerView: 1 },
                        360: { slidesPerView: 1 },
                        540: { slidesPerView: 2 },
                        768: { slidesPerView: 2 },
                        900: { slidesPerView: 3 },
                        1200: { slidesPerView: 3 },
                    }}
                >
                    { discussions.map((discussion) => (
                        <SwiperSlide key={discussion.id}>
                            <Link className="discussion-link" to={'/'+discussion.id+'/'+discussion.slug}>
                                <div className="discussion-item">
                                    <div className="dateTime">
                                        <p className="date">{ moment(discussion.date).format("D MMM YYYY") }</p>
                                        {/* <p className={"live-icon " + ( (moment().format('D') ===  moment(discussion.date).format('D')) ? '' : 'hidden') }>
                                            <img src="/assets/media/live.gif" alt="" />
                                        </p> */}
                                    </div>
                                    <h2 className="title" dangerouslySetInnerHTML={{ __html:discussion.title.rendered }}></h2>
                                    <div className="thum">
                                    <img src={discussion.yoast_head_json.og_image[0].url ?? '..//..//assets/media/550.png'} alt="" />
                                    </div>
                                    {/* <div className="discussion-footer-wrap">
                                        <p className="excerpt">Enter the chat room {`>`}</p>
                                        <div className="hour-ago"><span>{moment(discussion.date).fromNow()}</span></div>
                                    </div> */}
                                </div>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}
export default Discussion;
