import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import uuid from "react-uuid";
import DiscussionSkeleton from "../../Skeletons/DiscussionSkeleton";
import slugify from "slugify";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/thumbs";

// import required modules
import { Pagination, FreeMode, Thumbs } from "swiper";

export default function EventSingle() {
    const params = useParams();

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    
    const [singleEvent, setSingleEvent] = useState({});
    const [loader, setLoader] = useState(false);

    const [predictionResults, setPredictionResults] = useState([]);
    const [predictionAns, setPredictionAns] = useState([]);
    const [predictionTeams, setPredictionTeams] = useState([]);
    const [predictionUsers, setPredictionUsers] = useState([]);
    const [predictionLoader, setPredictionLoader] = useState(true);

    const getMatchAnsTeamName =  (userId = 0, match='', toss='', slug = '') => { 

        let toss_result = 'default_'+slug+'_toss';
        let match_result = 'default_'+slug+'_match';

        let userAns = predictionAns[userId];
        let teamname_toss = ''
        userAns.forEach((element, key) => {
            if( typeof element[match] !== 'undefined') { 

                let match_result_icon = '';
                if(typeof predictionResults[match_result] !== 'undefined' && predictionResults[match_result]  ){
                    if(predictionResults[match_result] === element[match] ) {
                        match_result_icon = '&#128512;';
                    } else {
                        match_result_icon = '&#128557;';
                    }
                }

                teamname_toss = '<p>Match '+element[match]+' '+match_result_icon+' </p>'
            }
        })

        userAns.forEach((element, key) => {
            if( typeof element[toss] !== 'undefined') { 

                let toss_result_icon = '';
                if(typeof predictionResults[toss_result] !== 'undefined' && predictionResults[toss_result]  ){
                    if(predictionResults[toss_result] === element[toss] ) {
                        toss_result_icon = '&#128512;';
                    } else {
                        toss_result_icon = '&#128557;';
                    }
                }

                teamname_toss = teamname_toss + '<p>Toss '+element[toss]+' '+toss_result_icon+'</p>';
            }
        })

        return teamname_toss;

    }



    useEffect(() => {

        fetch('https://cricdiction.expert/wp-json/wp/v2/events/'+params.id)
        .then(response => response.json())
        .then(singleEvent => {
            setSingleEvent(singleEvent);
            setLoader(true);
        })

        const fetchPredictionUsers = async () => {
            fetch('https://cricdiction.expert/wp-json/cricdiction/v1/latest-predictions/'+params.id)
            .then(response => response.json())
            .then(predictions => {

                let prediction_ans = []
                for (const [keys, value] of Object.entries(predictions.event_ans)) {
                    let sub_arry = [];
                    for (const [index, element] of Object.entries(value)) {
                        let sub_arr_arr = [];
                        sub_arr_arr[index] = element;
                        sub_arry.push(sub_arr_arr)
                       
                    }
                    prediction_ans[keys] = sub_arry;
                }

                setPredictionAns(prediction_ans);

                let userArr = []
                for (const [keys, value] of Object.entries(predictions.users)) {
                    userArr.push(value)
                    console.log(keys)
                }
                if(userArr.length){
                    setPredictionUsers(userArr);
                }

                let teamArr = []
                for (const [keys, value] of Object.entries(predictions.event_ops.teams)) {
                    console.log(keys)
                    let teamElement = [];
                    teamElement['home'] = value.home.name;
                    teamElement['homeRun'] = value.home.run;
                    teamElement['away'] = value.away.name;
                    teamElement['awayRun'] = value.away.run;
                    teamElement['subtitle'] = value.subtitle;
                    teamArr.push(teamElement)
                }

                if(teamArr.length){
                    setPredictionTeams(teamArr);
                }

                setPredictionLoader(false);

                let resultArr = []
                for (const [keys, value] of Object.entries(predictions.event_ops)) {
                    console.log(keys)
                    resultArr[keys] = value;
                }
               
                setPredictionResults(resultArr)
            })
        }
       


        fetchPredictionUsers();

    }, [params.id]);


  return (
    <>
        <div className="main-wrapper">
            <div className="page-single-event">
                <div className="page-title single-event-page-title">
                    <h5 className="page-name">Experts Prediction</h5>
                    <div className="entry-title">
                        { loader ? <h1 className="title" dangerouslySetInnerHTML={{ __html:singleEvent.title.rendered }}></h1>  : '' }
                        
                    </div>
                    <ul className="post-meta">
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" height="" viewBox="0 0 448 512">
                                <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"/>
                            </svg>
                            <span>{moment(singleEvent.date).format("D MMMM YYYY")}</span>
                        </li>
                    </ul>
                </div>

                <div className="event-wrap event-single-user">
                    {predictionLoader && <div className="discussion-skeleton"><DiscussionSkeleton items={3} /></div>}            
                    <Swiper
                        loop={true}
                        spaceBetween={20}
                        slidesPerView={3}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Pagination, Thumbs]}
                        className="mySwiper2"
                        

                        hashNavigation={{
                            watchState: true,
                            }}
                            pagination={{
                            clickable: true,
                            }}
                            

                        breakpoints={{
                            0: { slidesPerView: 1 },
                            360: { slidesPerView: 1.3 },
                            540: { slidesPerView: 2 },
                            768: { slidesPerView: 2.4 },
                            900: { slidesPerView: 3 },
                            1200: { slidesPerView: 3 },
                        }}
                    >
                        {
                            predictionUsers.map((prediction) => (
                                <SwiperSlide key={uuid()} data-hash={prediction.id} >
                                    
                                    <div className="current-matches-item">
                                        <img className="user-img" src={prediction.avatar !== '' ? prediction.avatar : 'https://cdn-icons-png.flaticon.com/512/1193/1193274.png'} alt={prediction.name} />
                                        <a 
                                            className=""
                                            href= {"https://cricdiction.expert/member/?p=" + prediction.user_login}
                                            target="_blank"
                                            rel="noreferrer"
                                            
                                        >
                                            <h5 className="name">{prediction.name}</h5>
                                        </a>
                                        <div className="divider"></div>

                                        {
                                            predictionTeams.map((team) => (
                                                <div key={uuid()} className="inner-result">
                                                    <h3>{team.home} vs {team.away}</h3>
                                                    <div dangerouslySetInnerHTML={{ __html:getMatchAnsTeamName(prediction.id,'team_'+slugify(team.home.toLowerCase(), '_')+'_vs_'+slugify(team.away.toLowerCase(), '_')+'_match', 'team_'+slugify(team.home.toLowerCase(), '_')+'_vs_'+slugify(team.away.toLowerCase(), '_')+'_toss', slugify(team.home.toLowerCase(), '_')+'_vs_'+slugify(team.away.toLowerCase(), '_')  ) }}></div>
                                                </div>
                                            ))
                                        }

                                    </div>

                                </SwiperSlide>
                            ))
                        }
                    </Swiper> 

                    <div className="user-thum-slider">

                    
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        loop={false}
                        spaceBetween={2}
                        slidesPerView={20}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Thumbs]}
                        centeredSlides
                        className="mySwiper"
                        breakpoints={{
                        280: { slidesPerView: 5 },
                        360: { slidesPerView: 6 },
                        540: { slidesPerView: 8 },
                        768: { slidesPerView: 10 },
                        900: { slidesPerView: 20 },
                        1200: { slidesPerView: 20 },
                    }}


                    >
                        {
                            predictionUsers.map((prediction) => (
                                <SwiperSlide key={uuid()} data-hash={prediction.id} >
                                    <img className="" src={prediction.avatar !== '' ? prediction.avatar : 'https://cdn-icons-png.flaticon.com/512/1193/1193274.png'} alt={prediction.name} />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper> 

                    </div>

                    <div className="team-equation">
                        {
                            predictionTeams.map((team) => (
                                <div key={uuid()} className="">
                                    <h3 className="match-between">{team.home} vs {team.away}</h3>
                                    <div className="wining-percentage">
                                        <div>
                                            <p>{team.home}</p>
                                        </div>
                                        <div>
                                            <p>{team.away}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
