import React from 'react';
// import { useLocation, Link } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ crumbs }) => {
//   const { pathname } = useLocation();

  return (
    <nav>
        <ul className="breadcrumbs">
            <li><Link to="/">Home</Link></li>
            {crumbs.map((crumb, index) => (
                <li key={index}>
                    {index === crumbs.length - 1 ? (
                        crumb.label
                    ) : (
                        <Link to={crumb.path}>{crumb.label}</Link>
                    )}
                </li>
            ))}
        </ul>
    </nav>
  );
};

export default Breadcrumb;