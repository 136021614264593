import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { DiscussionEmbed } from 'disqus-react';
import SingleFantastTipsSkeleton from "../../Skeletons/SingleFantasyTipsSkeleton";
import { Helmet } from 'react-helmet';
import EventSingleForDiscussion from "./EventDataForDiscussion";
import { Player } from '@lottiefiles/react-lottie-player';
import ball from '../../../lottie/sports-ball-loader.json'

const DiscussionSingle = () => {
    const params = useParams();
	const [events, setEvents] = useState([]);
    const [singleDiscussion, setSingleDiscussion] = useState({});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        fetch('https://www.cricdiction.com/wp-json/wp/v2/posts/'+params.id)
        .then(response => response.json())
        .then(singleDiscussion => {
            setSingleDiscussion(singleDiscussion);
			console.log("singleDiscussion?.acf?.event_id", singleDiscussion?.acf?.event_id);
			if (singleDiscussion?.acf?.event_id) {
				const event_ids = singleDiscussion.acf.event_id.split(',');
				setEvents(event_ids);
			}

            setLoader(true);
        })
    }, [params.id]);

	console.log("singleDiscussion", singleDiscussion);
	console.log("events",events);

    return(
        <>
            {loader ? 
                <>
					<Helmet>
						<title>{singleDiscussion.yoast_head_json.title}</title>
						<meta name="description" content={singleDiscussion.yoast_head_json.description} />
					</Helmet>
					<div className="main-wrapper">
						<div className="page-single-discussion">
							<div className="page-title single-discussion-page-title">
								<div className="title-top-bar">
									<div>
										<Link to="/prediction" className="page-name">Cricket Prediction</Link>
									</div>
									<ul className="social">
										<li>
											<a href="https://www.facebook.com/" target="blank">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
													<path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/>
												</svg>
											</a>
										</li>
										<li>
											<a href="https://twitter.com/" target="blank">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
													<path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/>
												</svg>
											</a>
										</li>
									</ul>
								</div>
								
								<div className="entry-title">
									<h1 className="title" dangerouslySetInnerHTML={{ __html:singleDiscussion.title.rendered }}></h1>
									<div className="subtitle" dangerouslySetInnerHTML={{ __html:singleDiscussion.excerpt.rendered }}></div>
								</div>
								<ul className="post-meta">
									<li>
									<svg xmlns="http://www.w3.org/2000/svg" height="" viewBox="0 0 448 512">
										<path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"/>
									</svg>
										<span>{moment(singleDiscussion.date).format("D MMMM YYYY")}</span>
									</li>
								</ul>
							</div>



							{events.length ? events.map((event, index) => {return(
								<div className="event-predictors" id={"event-"+event} key={index}>
									<EventSingleForDiscussion  event_id={parseInt(event)} />
								</div>
							)}) : ''}


							

							<div className="ball-animation">
								<Player src={ball} className="logo" style={{ height: '200px', width: '200px' }} loop autoplay />
							</div>
							
							{/* <div className="discussion-content" dangerouslySetInnerHTML={{ __html:singleDiscussion.content.rendered }}></div> */}
							<div className="discussion-wrap">
								<div className="">
									<DiscussionEmbed
										shortname='cricdiction'
										config={
											{
												url: 'https://cricdiction.com/'+ params.slug,
												identifier: params.id,
											}
										}
									/>
								</div>
							</div>
						</div>
                    </div>
				</>

            :
            <div className="main-wrapper">
                <div className="site-container">
                    <SingleFantastTipsSkeleton items={1} />    
                </div>
            </div>
            }
        </>
    )
}
export default DiscussionSingle;