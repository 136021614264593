import React from "react";
import Discussion from "./Discussion/Discussion";
import FantasyTips from "./FantasyTips/FantasyTips";
import CricketNews from "./CricketNews/CricketNews";
import { Helmet } from 'react-helmet';
const Home = () => {
    return (
        <>
            <Helmet>
                <title>Criclysis - Your Source for Cricket News, Scores, and Analysis</title>
                <meta name="keywords" content="cricket news, cricket scores, cricket analysis, cricket teams, cricket players" />
                <meta name="description" content="Explore the world of cricket with Criclysis. Get the latest cricket news, live scores, in-depth analysis, and updates on your favorite cricket teams and players. Stay connected with the cricketing world at Criclysis.com." />
            </Helmet>
            <div className="main-wrapper scrollbar">   
                <Discussion />
                <FantasyTips />
                <CricketNews />
            </div>
        </>
        
    )
}
export default Home;