import React, { useEffect } from "react";
import Routers from "./Routers";
import ReactGA from 'react-ga';
import TagManager from "react-gtm-module";
ReactGA.initialize('G-KCVX3MY7NV');

const App = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);

      useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-MJT58VZP' }); // Replace with your GTM Container ID
      }, []);
    return (
        <>
            <Routers />
        </>
    );
}
export default App;