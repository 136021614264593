import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Post = ({ post }) => (
  	<div className="news-item mb-8">
		<Link to={'/fantasy-cricket/' + post.id + '/' + post.slug} className="news-link">
            <div className="featured-image-wrapper">
                <div className="news-featured-image" style={{ backgroundImage: `url(${post.yoast_head_json.og_image[0].url})` }}></div>
                <p className="date">
                <span>{moment(post.date).format("D MMM YYYY")}</span>
            </p>
            </div>
            <h2 className="title" dangerouslySetInnerHTML={{ __html:post.title.rendered }}></h2>
		</Link>
  	</div>
);

export default Post;