import React from "react";
import CricketNewsPostList from "./CricketNewsList";
import Breadcrumb from '../../../Breadcrumb';
import { Helmet } from 'react-helmet';
import CurrentEventMatch from '../../Home/CurrentMatch/CurrentMatchAll'

const CricketNewsIndex = () => {
    const breadcrumbs = [
		{ label: 'Cricket news', path: '/current' },
	];
    return(
        <>
			<Helmet>
				<title>Criclysis Cricket News - Latest Updates, Scores, and Analysis</title>
                <meta name="keywords" content="cricket news, cricket updates, cricket scores, cricket analysis, cricket matches, cricket teams, cricket players" />
                <meta name="description" content="Stay updated with the latest cricket news, scores, and in-depth analysis on Criclysis. Get insights into cricket matches, teams, and players from around the world." />
            </Helmet>
			<div className="main-wrapper scrollbar">
				<div className="header-current-matches">
					<CurrentEventMatch />
				</div>
				<div className="main-content">
					<div className="page-titlebar">
						<Breadcrumb crumbs={breadcrumbs} />
						<h1 className="entry-title">Latest cricket news</h1>
					</div>
					<CricketNewsPostList />      
				</div>
			</div>
		</>
    )
}
export default CricketNewsIndex;