import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

const MenuHideShow = () => {
    const [isSectionActive, setIsSectionActive] = useState(false);

    const toggleSectionClass = () => {
      setIsSectionActive(!isSectionActive);
    };

    const sectionClassName = `section ${isSectionActive ? 'menu-sliding-wrapper active' : 'menu-sliding-wrapper'}`;

  return (
    <>
      	<button className="sliding-icon" onClick={toggleSectionClass}>
        	<FontAwesomeIcon icon={faBars} />
      	</button>
        <div className={sectionClassName}>
            <div className="menu-sliding-bar-grid">
                <div className="sliding-bar-menus">
                    <ul className="menu-list">
                        <li className="menu-item">
                            <a className="menu-link" href="/">Home
                                <svg xmlns="http://www.w3.org/2000/svg" height="" viewBox="0 0 512 512">
                                    <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/>
                                </svg>
                            </a>
                        </li>
                        <li className="menu-item">
                            <a className="menu-link" href="/discussion">Discussion
                                <svg xmlns="http://www.w3.org/2000/svg" height="" viewBox="0 0 512 512">
                                    <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/>
                                </svg>
                            </a>
                        </li>
                        <li className="menu-item">
                            <a className="menu-link" href="/prediction">Prediction
                                <svg xmlns="http://www.w3.org/2000/svg" height="" viewBox="0 0 512 512">
                                    <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/>
                                </svg>
                            </a>
                        </li>
                        <li className="menu-item">
                            <a className="menu-link" href="/fantasy-tips">Fantasy Tips
                                <svg xmlns="http://www.w3.org/2000/svg" height="" viewBox="0 0 512 512">
                                    <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/>
                                </svg>
                            </a>
                        </li>
                        <li className="menu-item">
                            <a className="menu-link" href="https://cricket.criclysis.com/">News
                                <svg xmlns="http://www.w3.org/2000/svg" height="" viewBox="0 0 512 512">
                                    <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="sliding-bar-content scrollbar">

                </div>
                <div className="sliding-bar-sidebar">
                    <div className="close-icon">
                        <button className="sliding-icon" onClick={toggleSectionClass}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default MenuHideShow;