import React from "react";
import { Link } from "react-router-dom";
import Menus from "./Menus";
import SlidingBar from "./SlidingBar";
// import { Player } from '@lottiefiles/react-lottie-player';
// import logo from '../../../src/lottie/cricket-ball.json'

const Header = () => {
  	return (
     	<header className="header">
			<div className="site-container">
				<div className="header-wrapper">
					<div className="menu-wrap">
						<div className="cric-logo">
							<Link to="/">
								<img src="/assets/media/criclysis.png" alt="" style={{maxWidth: '99px'}} />
								{/* <Player src={logo} className="logo" style={{ height: '50px', width: '50px' }} loop autoplay /> */}
							</Link>
						</div>
						<div className="vertical-menus">
							<Menus />
						</div>
					</div>
					<div className="menu-sliding">
						<SlidingBar />
					</div>
				</div>
			</div>
		</header>
    );
}
export default Header;