import React, {useEffect } from "react";
// import { Link } from "react-router-dom";

export default function Login() {

 // const [data, setData] = useState([]);
 useEffect(() => {

    async function fetchData() {
      var apiHost = 'https://cricdiction.expert/wp-json';
      fetch(apiHost + '/jwt-auth/v1/token', {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            username: 'kawser@phase3solution.com',
            password: 'phase3@__2021' 
          }),
        })
        .then((response) => response.json())
        .then((responseJson) => {
        localStorage.setItem('jwt_token', responseJson.token)
        localStorage.setItem('nicename', responseJson.user_nicename)
        
          console.log(responseJson.token);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    fetchData();
  }, []);



  return (
    <div>Login</div>
  )
}
