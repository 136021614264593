import React from "react";
import { Link } from "react-router-dom";

const Menus = () => {
    return (
        
        <ul className="rotate-menus">
            <li className="nav-item">
                <Link to="/discussion">Discussion</Link>
            </li>
            <li className="nav-item">
                <Link to="/prediction">Prediction</Link>
            </li>
            <li className="nav-item">
                <Link to="https://cricket.criclysis.com/category/match-preview">Match Preview</Link>
            </li>
            <li className="nav-item">
                <Link to="https://cricket.criclysis.com/">News</Link>
            </li>
        </ul>
    );
}
export default Menus;