import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Post from './FantasyPostComponent';
import FantastTipsSkeleton from "../../Skeletons/FantasyTipsSkeleton";

const FantasyTipsPostList = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    

    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `https://www.cricdiction.com/wp-json/wp/v2/posts?categories=1063&per_page=8&page=${page}`
                );
                setPosts(prevPosts => [...prevPosts, ...response.data]);
            } catch (error) {
                console.error('Error fetching posts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, [page]);

    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    return (
        <>
            <div className="cricket-news-wrapper grid gap-8 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
                {posts.map(post => (
                    <Post key={post.id} post={post} />
                ))}
            </div>
            {loading && <div className="discussion-skeleton grid gap-8 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1"><FantastTipsSkeleton items={8} /></div>}
            {!loading && (
                <div className="center mt-8">
                    <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" onClick={loadMore}>
                        <span>Load More</span>
                    </button>
                </div>
            )}
        </>
    );
};

export default FantasyTipsPostList;