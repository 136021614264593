import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import uuid from "react-uuid";
import DiscussionSkeleton from "../../Skeletons/DiscussionSkeleton";
import slugify from "slugify";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/thumbs";

// import required modules
import { Pagination, FreeMode, Thumbs } from "swiper";
import moment from "moment";

export default function EventSingleForDiscussion({event_id}) {

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const [predictionResults, setPredictionResults] = useState([]);
    const [predictionAns, setPredictionAns] = useState([]);
    const [predictionTeams, setPredictionTeams] = useState([]);
    const [predictionUsers, setPredictionUsers] = useState([]);
    const [predictionLoader, setPredictionLoader] = useState(true);

    const getMatchAnsTeamName =  (userId = 0, match='', toss='', slug = '') => { 

        let toss_result = 'default_'+slug+'_toss';
        let match_result = 'default_'+slug+'_match';

        let userAns = predictionAns[userId];
        let teamname_toss = ''
        userAns.forEach((element, key) => {
            if( typeof element[match] !== 'undefined') { 

                let match_result_icon = '';
                if(typeof predictionResults[match_result] !== 'undefined' && predictionResults[match_result]  ){
                    if(predictionResults[match_result] === element[match] ) {
                        match_result_icon = '&#128512;';
                    } else {
                        match_result_icon = '&#128557;';
                    }
                }

                teamname_toss = '<p class="match"><span>Match:</span> '+element[match]+' '+match_result_icon+' </p>'
            }
        })

        userAns.forEach((element, key) => {
            if( typeof element[toss] !== 'undefined') { 

                let toss_result_icon = '';
                if(typeof predictionResults[toss_result] !== 'undefined' && predictionResults[toss_result]  ){
                    if(predictionResults[toss_result] === element[toss] ) {
                        toss_result_icon = '&#128512;';
                    } else {
                        toss_result_icon = '&#128557;';
                    }
                }

                teamname_toss = teamname_toss + '<p class="toss"><span>Toss:</span> '+element[toss]+' '+toss_result_icon+'</p>';
            }
        })

        return teamname_toss;

    }



    useEffect(() => {
        const fetchPredictionUsers = async () => {
            fetch('https://cricdiction.expert/wp-json/cricdiction/v1/latest-predictions/'+event_id)
            .then(response => response.json())
            .then(predictions => {

                let prediction_ans = []
                for (const [keys, value] of Object.entries(predictions.event_ans)) {
                    let sub_arry = [];
                    for (const [index, element] of Object.entries(value)) {
                        let sub_arr_arr = [];
                        sub_arr_arr[index] = element;
                        sub_arry.push(sub_arr_arr)
                       
                    }
                    prediction_ans[keys] = sub_arry;
                }

                setPredictionAns(prediction_ans);

                let userArr = []
                for (const [keys, value] of Object.entries(predictions.users)) {
                    userArr.push(value)
                    console.log(keys)
                }
                if(userArr.length){
                    setPredictionUsers(userArr);
                }

                let teamArr = []
                for (const [keys, value] of Object.entries(predictions.event_ops.teams)) {
                    console.log(keys)
                    let teamElement = [];
                    teamElement['home'] = value.home.name;
                    teamElement['homeRun'] = value.home.run;
                    teamElement['away'] = value.away.name;
                    teamElement['awayRun'] = value.away.run;
                    teamElement['subtitle'] = value.subtitle;
                    teamArr.push(teamElement)
                }

                if(teamArr.length){
                    setPredictionTeams(teamArr);
                }

                setPredictionLoader(false);

                let resultArr = []
                for (const [keys, value] of Object.entries(predictions.event_ops)) {
                    console.log(keys)
                    resultArr[keys] = value;
                }
               
                setPredictionResults(resultArr)
            })
        }
        fetchPredictionUsers();
    }, [event_id]);

    // const [predictionResults, setPredictionResults] = useState([]);
    // const [predictionAns, setPredictionAns] = useState([]);
    // const [predictionTeams, setPredictionTeams] = useState([]);
    // const [predictionUsers, setPredictionUsers] = useState([]);
    // const [predictionLoader, setPredictionLoader] = useState(true);

    console.log("event_id", event_id);
    console.log("predictionResults", predictionResults);
    console.log("predictionAns", predictionAns);
    console.log("predictionTeams", predictionTeams);
    console.log("predictionUsers", predictionUsers);
    console.log("predictionLoader", predictionLoader)


    if (!event_id) {
        return '';
    }

  return (
    <>
       
    <div className="event-wrap event-single-user">
        {predictionLoader && <div className="discussion-skeleton"><DiscussionSkeleton items={3} /></div>}     
        { predictionTeams.map((team) => (
            <div key={uuid()} className="match-info">
                <h3 className="match-between">{team.home} vs {team.away}</h3>
                <p className="sub">{team.subtitle}, {moment(predictionResults?.teams[1]?.end).format("LT")}</p>
                <p className="date">{moment(predictionResults?.teams[1]?.end).format("ll")}</p>
            </div>
        ))}       
        <Swiper
            loop={true}
            spaceBetween={20}
            slidesPerView={3}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Pagination, Thumbs]}
            className="mySwiper2"
            

            hashNavigation={{
                watchState: true,
                }}
                pagination={{
                clickable: true,
                }}
                

            breakpoints={{
                0: { slidesPerView: 1 },
                360: { slidesPerView: 1.3 },
                540: { slidesPerView: 2 },
                768: { slidesPerView: 2.4 },
                900: { slidesPerView: 3 },
                1200: { slidesPerView: 3 },
            }}
        >
            {
                predictionUsers.map((prediction) => (
                    <SwiperSlide key={uuid()} data-hash={prediction.id} >
                        
                        <div className="current-matches-item">
                            <img className="user-img" src={prediction.avatar !== '' ? prediction.avatar : 'https://cdn-icons-png.flaticon.com/512/1193/1193274.png'} alt={prediction.name} />
                            <a 
                                className="user-name"
                                href= {"https://cricdiction.expert/member/?p=" + prediction.user_login}
                                target="_blank"
                                rel="noreferrer"
                                
                            >
                                <p className="name">{prediction.name}</p>
                            </a>
                            <div className="divider"></div>

                            {
                                predictionTeams.map((team) => (
                                    <div key={uuid()} className="inner-result">
                                        <div dangerouslySetInnerHTML={{ __html:getMatchAnsTeamName(prediction.id,'team_'+slugify(team.home.toLowerCase(), '_')+'_vs_'+slugify(team.away.toLowerCase(), '_')+'_match', 'team_'+slugify(team.home.toLowerCase(), '_')+'_vs_'+slugify(team.away.toLowerCase(), '_')+'_toss', slugify(team.home.toLowerCase(), '_')+'_vs_'+slugify(team.away.toLowerCase(), '_')  ) }}></div>
                                    </div>
                                ))
                            }

                        </div>

                    </SwiperSlide>
                ))
            }
        </Swiper> 

        <div className="user-thum-slider">

        
        <Swiper
            onSwiper={setThumbsSwiper}
            loop={false}
            spaceBetween={2}
            slidesPerView={20}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Thumbs]}
            className="mySwiper"
            breakpoints={{
            280: { slidesPerView: 5 },
            360: { slidesPerView: 6 },
            540: { slidesPerView: 8 },
            768: { slidesPerView: 10 },
            900: { slidesPerView: 20 },
            1200: { slidesPerView: 20 },
        }}


        >
            {
                predictionUsers.map((prediction) => (
                    <SwiperSlide key={uuid()} data-hash={prediction.id} >
                        <img className="" src={prediction.avatar !== '' ? prediction.avatar : 'https://cdn-icons-png.flaticon.com/512/1193/1193274.png'} alt={prediction.name} />
                    </SwiperSlide>
                ))
            }
        </Swiper> 

        </div>
    </div>
         
    </>
  )
}
