import React from 'react';
import DiscussionPostList from './DiscussionPostList';
import Breadcrumb from '../../../Breadcrumb';
import { Helmet } from 'react-helmet';

function Discussionindex () {
	const breadcrumbs = [
		// { label: 'Home', path: '/' },
		{ label: 'Discussion', path: '/current' },
	];
  	return (
		<>
			<Helmet>
				<title>Criclysis Discussion Forum - Cricket Conversations and Analysis</title>
                <meta name="keywords" content="cricket discussion, cricket forum, cricket analysis, cricket conversations, cricket teams, cricket players" />
                <meta name="description" content="Join the Criclysis Discussion Forum for engaging cricket conversations and in-depth analysis. Share your thoughts on cricket teams, players, and matches." />
            </Helmet>
			<div className="main-wrapper">				
				<div className="main-content">
					<div className="site-container">
						<div className="page-titlebar">
							<Breadcrumb crumbs={breadcrumbs} />
							<h1 className="entry-title">Live Cricket Tips</h1>
						</div>
						<DiscussionPostList />      
					</div>
				</div>
			</div>
		</>
  	);
}

export default Discussionindex;