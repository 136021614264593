import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import FantastTipsSkeleton from "../../Skeletons/FantasyTipsSkeleton";
const FantasyTips = () => {
    const [tips, setTips] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetch('https://cricket.criclysis.com/wp-json/wp/v2/posts?categories=425&per_page=4', { cache: 'no-store' })
        .then(response => response.json())
        .then(tips => {
            setTips(tips);
            setIsLoading(false);
        })
    }, []);

    return (
        <div className="cricket-news-home-list match-preview">
            <div className="site-container">
                <div className="cricket-news-wrapper">
                    <div className="section-title flex-title mb-8">
                        <h2 className="title">Match Preview</h2>
                        <div className="view-all">
                            <Link to="https://cricket.criclysis.com/category/match-preview" target="_blank" className="view-all-link">
                                <span>View all</span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                    {isLoading && <div className="grid gap-8 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1"><FantastTipsSkeleton items={4} /></div>}
                    <div className="grid gap-8 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
                        { tips.map((item) => (
                            <div className="news-item" key={item.id}>
                                <Link to={item.link} className="news-link">
                                    <div className="featured-image-wrapper">
                                        <div className="news-featured-image" style={{ backgroundImage: `url(${item.yoast_head_json.og_image[0].url})` }}></div>
                                        <p className="date">
                                            <span>{moment(item.date).format("D MMM YYYY")}</span>
                                        </p>
                                    </div>
                                    <h2 className="title" dangerouslySetInnerHTML={{ __html:item.title.rendered }}></h2>
                                    <div className="hour-ago">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="" viewBox="0 0 512 512">
                                            <path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/>
                                        </svg>
                                        <span>{moment(item.date).fromNow()}</span>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default FantasyTips;