import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import SingleFantastTipsSkeleton from "../Skeletons/SingleFantasyTipsSkeleton";
import { Helmet } from 'react-helmet';
const SingleFantasyTips = () => {
    const [singleTips, setSingleTips] = useState({});
    const [loader, setLoader] = useState(false);
    const params = useParams();

    useEffect(() => {
        fetch('https://www.cricdiction.com/wp-json/wp/v2/posts/'+params.id)
        .then (res => res.json())
        .then(singleTips => {
            setSingleTips(singleTips);
            setLoader(true);
        })
    }, [params.id])

    return (
        <>
            {loader ?
                <>
                    <Helmet>
                        <title>{singleTips.yoast_head_json.title}</title>
                        <meta name="description" content={singleTips.yoast_head_json.description} />
                    </Helmet>
                    <div className="main-wrapper">
                        <div className="page-single-fantasy-tips">
                            <div className="page-title single-tips-page-title">
                                <h5 className="page-name">Cricket match prediction & Fantasy tips</h5>
                                <div className="entry-title">
                                    <h1 className="title" dangerouslySetInnerHTML={{ __html:singleTips.title.rendered }}></h1>
                                    <div className="subtitle" dangerouslySetInnerHTML={{ __html:singleTips.excerpt.rendered }}></div>
                                </div>
                                <ul className="post-meta">
                                    <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="" viewBox="0 0 448 512">
                                        <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"/>
                                    </svg>
                                        <span>{singleTips.yoast_head_json.author}</span>
                                    </li>
                                    <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="" viewBox="0 0 448 512">
                                        <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"/>
                                    </svg>
                                        <span>{moment(singleTips.date).format("D MMMM YYYY")}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="featured-image center">
                                <img src={singleTips.yoast_head_json.og_image[0].url ?? '..//..//assets/media/550.png'} alt="" />
                            </div>
                            <div className="tips-content" dangerouslySetInnerHTML={{ __html:singleTips.content.rendered }}></div>
                        </div>
                    </div>
                </> 
                :
                <div className="main-wrapper">
                    <div className="site-container">
                        <SingleFantastTipsSkeleton items={1} />    
                    </div>
                </div>
            }
        </>
        
    );
}
export default SingleFantasyTips;