import React from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'


const DiscussionSkeleton = ({items}) => {
    return Array(items)
    .fill(0)
    .map((item, i) => (
        <div className="discussion-grid-wrap" key={i}>
            <div className="discussion-link">
                <div className="discussion-item">
                    <div className="dateTime">
                        <div className="date"><Skeleton height={30} width={150} /></div>
                    </div>
                    <div className="title"><Skeleton height={20} count={4} /></div>
                    <div className="thum"><Skeleton height={400} borderRadius={10} /></div>
                </div>
            </div>
        </div>
    ));
    
}
export default DiscussionSkeleton;