import React from 'react'
import { Link } from 'react-router-dom'

const FixtureCard = ({category}) => {
  return (
    <div className="item-wrap">
        <div className="item-header">
            <h2>{category.name}</h2>
            {/* <div className="">
                <Link to="#">View All</Link>  
            </div> */}
        </div>
        <div className="item-grid">
            {
                category.fixtures.length ? (
                    category.fixtures.map((fixture, key) => {return(
                        <div key={key} className="single-match-wrap">
                            <p className="match-info">{fixture.sub}, {fixture.time}, {fixture.season.name}</p>
                            <div className="team-wrap">
                                <div className="teams">
                                    <div className="team-name">
                                        <img width={20} height={20} src={fixture.home.image} alt="home_team_logo.png" />
                                        <p>{fixture.home.name}</p>
                                    </div>
                                    <div className="runs">
                                        {fixture.home.run ?  <span>{fixture.home.run}</span> : ''}
                                    </div>
                                </div>
                                <div className="teams">
                                    <div className="team-name">
                                        <img  width={20} height={20} src={fixture.away.image} alt="away_team_logo.png" />
                                        <p>{fixture.away.name}</p>
                                    </div>
                                    <div className="runs">
                                        {fixture.away.run ?  <span>{fixture.away.run}</span> : ''}
                                    </div>
                                </div>
                            </div>
                            {fixture.status ? <p className="result">{fixture.status}</p>  : ''} 
                            <div className="match-btns">
                                <Link to={'/event/' + fixture?.event.ID + '/' + fixture?.event.slug } className="match-link">View Prediction</Link>
                                <Link to="/discussion" className="match-link">Discussion</Link>
                            </div>
                        </div>
                    )})
                ) : "No Team Found!"
            }
        </div>
    </div>
  )
}

export default FixtureCard