import React from "react";
import FantasyTipsPostList from "./FantasyTipsList";
import Breadcrumb from '../../../Breadcrumb';
import { Helmet } from 'react-helmet';
import CurrentEventMatch from '../../Home/CurrentMatch/CurrentMatchAll'

const FantasyTipsIndex = () => {
    const breadcrumbs = [
		{ label: 'Fantasy Tips', path: '/current' },
	];
    return(
		<>
			<Helmet>
				<title>Criclysis Fantasy Cricket Tips - Expert Advice for Fantasy Cricket</title>
                <meta name="keywords" content="fantasy cricket tips, fantasy cricket advice, player selections, match previews, fantasy cricket strategies, fantasy cricket leagues" />
                <meta name="description" content="Discover expert fantasy cricket tips and advice on Criclysis. Get insights on player selections, match previews, and strategies to dominate fantasy cricket leagues." />
            </Helmet>
			<div className="main-wrapper scrollbar">
				<div className="header-current-matches">
					<CurrentEventMatch />
				</div>
				<div className="main-content">
					<div className="page-titlebar">
						<Breadcrumb crumbs={breadcrumbs} />
						<h1 className="entry-title">Cricket match prediction & Fantasy tips</h1>
					</div>
					<FantasyTipsPostList />      
				</div>
			</div>
		</>
    )
}
export default FantasyTipsIndex;