import React from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const CurrentMatchSkeleton = ({items}) => {
    return Array(items)
        .fill(0)
        .map((item, i) => (
            <div className="wrap" key={i} >
                <div className="date"><Skeleton height={10} width={120} /></div> 
                <div className="sub-title"><Skeleton height={10} width={140} /></div> 
                <div className="teams">
                   <Skeleton width={20} height={20} circle />
                    <Skeleton height={15} />
                </div> 
                <div className="teams">
                   <Skeleton width={20} height={20} circle />
                    <Skeleton height={15} />
                </div>
                <div className="button"><Skeleton width={120} /></div> 
            </div>
        ));
    
}
export default CurrentMatchSkeleton;