import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import CurrentMatchSkeleton from "../../Skeletons/CurrentMatchSkeletor";
// import moment from "moment";


const CurrentMatchAll = () => {

   
    const [matches, setMatches] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

  

    useEffect(() => {
        getRecentEventMatches();
    }, [])

    const getRecentEventMatches = async () => {
     
        fetch('https://cricdiction.expert/wp-json/cricdiction/v1/recent-event-matches', { cache: 'no-store' })
        .then(response => response.json())
        .then(matches => {
            setMatches(matches);
            setIsLoading(false);
        })
    }

    return (
        <>
            {isLoading && <div className="discussion-skeleton flex justify-between"><CurrentMatchSkeleton items={5} /></div>}
            {
                matches.length ?
                <>
                    <Swiper
                        spaceBetween={15}
                        slidesPerView={5}
                        breakpoints={{
                            0: { slidesPerView: 1 },
                            540: { slidesPerView: 2 },
                            768: { slidesPerView: 3 },
                            900: { slidesPerView: 3 },
                            1366: { slidesPerView: 4 },
                            1700: { slidesPerView: 5 },
                        }}
                    >
                        {
                        
                            matches.map((match, index) => (
                                <SwiperSlide key={index}>
                                    <Link to={'/event/' + match?.event_id + '/' + match?.event_slug } className="current-match-link">
                                        <div className="current-matches-item">
                                            <p className="date">{match?.match_time}</p>
                                            <p className="subtitle">{match?.match_subtitle}</p>
                                            <div className="team-wrap">
                                                <div className="team-name">
                                                    <img src={match?.home_image ? match?.home_image: "/assets/media/no-flag.png"} alt="" />
                                                    <p className="dark:text-white">{match?.home_team}</p>
                                                </div>
                                                <div className="separator"></div>
                                                <div className="team-name two">
                                                    <p className="dark:text-white">{match?.away_team}</p>
                                                    <img src={match?.away_image ? match?.away_image: "/assets/media/no-flag.png"} alt="" />
                                                </div>
                                            </div>
                                            <div className="current-matches-footer">View Prediction</div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            ))
                        
                        }
                    </Swiper>
                </>
                :
                <div>
            </div>
                
            }
        </>
    )

    
}
export default CurrentMatchAll;