import React from "react";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import CurrentMatchAll from "./CurrentMatchAll";
// import CurrentMatchByCategoriesOne from "./CurrentMatchByCategoriesOne";


export default function CurrentEventMatch() {

    return (
        <div className="header-current-matches">
            <div className="site-container">
                <CurrentMatchAll />
                {/* <Tabs className="header-current-matches-tab">
                    <TabList>
                        <Tab>All Matches</Tab>
                        <Tab>Tab 2</Tab>
                        <Tab>Tab 3</Tab>
                    </TabList>

                    <TabPanel>
                        
                    </TabPanel>
                    <TabPanel>
                        
                    </TabPanel>
                    <TabPanel>
                        <h2>Content for Tab 3</h2>
                        <p>This is the content of Tab 3.</p>
                    </TabPanel>
                </Tabs> */}
            </div>
        </div>
    )
}
